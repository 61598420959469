export default function Transaction({ txHash }: { txHash: string }) {
  return (
    <a
      href={`https://polygonscan.com/tx/${txHash}`}
      target="_blank"
      rel="noreferrer"
      className="address"
    >
      {txHash}
    </a>
  );
}
