import { useEthers } from "@usedapp/core";

export default function ConnectButton() {
  const { activateBrowserWallet } = useEthers();

  return (
    <>
      <div className="title">
        <h1>PDL Faucet</h1>
      </div>
      <button
        className="metamask-connect-button"
        onClick={() => activateBrowserWallet()}
      >
        Connect wallet
      </button>
    </>
  );
}
