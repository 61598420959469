import { useEthers } from "@usedapp/core";
import { useState } from "react";
import Loader from "./Loader";
import Address from "./Address";

type HandleSubmitCallback = (
  username: string,
  password: string
) => Promise<void>;

type FundsRequestFormProps = {
  handleSubmit: HandleSubmitCallback;
  error?: string;
};

function FundsRequestForm({ handleSubmit, error }: FundsRequestFormProps) {
  const { account } = useEthers();

  const [processing, setProcessing] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [localError, setLocalError] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (username.includes("@")) {
      setLocalError("Please enter your username, not your email address.");
      return;
    }
    setProcessing(true);
    await handleSubmit(username, password);
    setProcessing(false);
  };

  return (
    <>
      <p>Use your Imperial credentials to request funds.</p>
      <p className="funds-note">
        The funds will be sent to <Address account={account || ""} />.
      </p>
      <form className="login-form" onSubmit={onSubmit}>
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        {(error || localError) && (
          <p className="error">{error || localError}</p>
        )}
        {processing ? (
          <button className="submit-btn" disabled>
            <Loader size={15} color="#fff" />
          </button>
        ) : (
          <input className="submit-btn" type="submit" value="Request funds" />
        )}
      </form>
    </>
  );
}

export default FundsRequestForm;
