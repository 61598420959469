import Loader from "./Loader";
import FundsRequestForm from "./FundsRequestForm";
import Address from "./Address";
import useFundedStatus, { FundedStatusQuery } from "../hooks/useFundedStatus";
import { useEthers } from "@usedapp/core";
import { useState } from "react";
import useRequestFunding from "../hooks/useRequestFunding";
import Transaction from "./Transaction";

function FundsRequest() {
  const { account } = useEthers();
  const [query, setQuery] = useState<FundedStatusQuery>({ address: account });
  const { fundedStatus, initialLoading, refreshStatus } =
    useFundedStatus(query);
  const { requestFunding, error } = useRequestFunding();

  const handleSubmit = async (username: string, password: string) => {
    const newQuery = { username, address: account };
    setQuery(newQuery);
    const newStatus = await refreshStatus(newQuery);
    if (newStatus?.used || !account) return;

    try {
      await requestFunding(account, username, password);
      refreshStatus();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="login-container">
      <h1 className="form-heading">PDL Fund request</h1>
      {initialLoading ? (
        <Loader size={100} />
      ) : fundedStatus?.address ? (
        <p>
          The funds have been sent to{" "}
          <Address account={fundedStatus?.address} />
          {fundedStatus.tx_hash && (
            <>
              <br />
              in transaction <Transaction txHash={fundedStatus.tx_hash} />
            </>
          )}
        </p>
      ) : (
        <FundsRequestForm handleSubmit={handleSubmit} error={error} />
      )}
    </div>
  );
}

export default FundsRequest;
