export default function Address({ account }: { account: string }) {
  return (
    <a
      href={`https://polygonscan.com/address/${account}`}
      target="_blank"
      rel="noreferrer"
      className="address"
    >
      {account}
    </a>
  );
}
