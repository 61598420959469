import { useState } from "react";
import { REQUEST_FUNDS_API_URL } from "../constants";

export type RequestFundingResponse = {
  tx_hash: string;
};

export default function useRequestFunding() {
  const [error, setError] = useState<string | undefined>();

  const requestFunding = async (
    account: string,
    username: string,
    password: string
  ): Promise<RequestFundingResponse> => {
    const data = { address: account, username, password };
    const response = await fetch(REQUEST_FUNDS_API_URL, {
      body: JSON.stringify(data),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const result = await response.json();
    if (response.status !== 200) {
      setError(result.error);
      throw new Error(result.error);
    }
    return result;
  };

  return { requestFunding, error };
}
