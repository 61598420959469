import { RingLoader } from "react-spinners";

type LoaderProps = {
  size: number;
  color?: string;
};

export default function Loader({ size, color }: LoaderProps) {
  return <RingLoader className="loader" size={size} color={color || "#123abc"} />;
}
