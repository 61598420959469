import { useCallback, useEffect, useState } from "react";
import { QUERY_FUNDS_API_URL } from "../constants";

export type FundedStatusQuery = {
  username?: string;
  address?: string;
};

export type FundedStatusResponse = {
  username: string;
  address: string;
  used: boolean;
  tx_hash: string;
};

export type FundedStatusHook = {
  fundedStatus: FundedStatusResponse | undefined;
  refreshStatus: (query?: FundedStatusQuery) => Promise<FundedStatusResponse>;
  loading: boolean;
  initialLoading: boolean;
};

export default function useFundedStatus(
  query: FundedStatusQuery
): FundedStatusHook {
  const [fundedStatus, setFundedStatus] = useState<FundedStatusResponse>();
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const isFunded = fundedStatus?.used;

  const refreshStatus = useCallback(
    async (queryOverride?: FundedStatusQuery) => {
      const url =
        `${QUERY_FUNDS_API_URL}?` +
        new URLSearchParams(queryOverride || query).toString();
      const response = await fetch(url);
      if (response.status !== 200) return;
      const status = await response.json();
      if (status.used !== undefined && status.used !== isFunded) {
        setFundedStatus(status);
      }
      setLoading(false);
      setInitialLoading(false);
      return status;
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    setLoading(true);
    refreshStatus();
  }, [refreshStatus]);

  return { fundedStatus, refreshStatus, initialLoading, loading };
}
